/* CustomStyles.css */
.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 8px 8px;
    /* Adjust the padding values according to your needs */
}

.sticky-menu {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    /* Adjust the z-index as needed */
    margin-bottom: 45px;
}

.section {
    /* min-height: 70vh; */
    margin-top: auto;
    margin-bottom:auto;
    padding:20px;
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    /* scroll-snap-align: start; */
    /* opacity: 1; */
    /* transition: opacity 0.5s ease; */
    /* width: 100%; */
}

.first {
    min-height: calc(100dvh - 100px);
}

/* .section:nth-child(odd) {
    background-color: #ffffff;
}

.section:nth-child(even) {
    background-color: #9e9e9e;
} */

@keyframes up-down {
    0% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(20px);
    }
}

.image-effect {
    animation-name: up-down;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-fill-mode: both;
    align: center;
    display: block;
    margin: auto;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Define the animation for slide-in from right */
@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Apply the initial state for elements with the 'slide-in' class */
.slide-in-left,
.slide-in-right {
    opacity: 0;
}

.slide-in-left {
    transform: translateX(-100%);
}

.slide-in-right {
    transform: translateX(100%);
}

/* Apply the animation to the 'in-view' class */
.in-view {
    animation: 1s ease-out forwards;
}

.in-view.slide-in-left {
    animation-name: slideInFromLeft;
}

.in-view.slide-in-right {
    animation-name: slideInFromRight;
}

.card {
    flex:1;
    /* height:100%; */
    padding: 20px;
    margin:20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    align-self:stretch;
  }
  
  .card-title {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .separator {
    margin: 0;
    border: 1px solid #ddd;
  }
  
  .card-text {
    font-size: 1em;
    margin-top: 10px;
  }

  /* Styles for the image within the element with class .first */
.first img {
    /* Add your specific styles here */
    max-height: 35vh; /* for mobile */
  }
  
  /* Media query for desktop (min-width: 768px) */
  @media (min-width: 768px) {
    .first img {
      max-height: 70vh; /* for desktop */
    }
  }