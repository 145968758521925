/* .card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: opacity 0.2s ease-out;
    position: absolute;
    width: 90vw;
    height: 80%; 
    padding:0;
    margin-top:0;
    margin-left:auto;
    margin-right:auto;
} */

.card {
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    text-align: center;
    transition: opacity 0.2s ease-out;
    position: absolute;
    width: 90vw;
    height: calc(100svh - 220px); 
    /* height:100%; */
    /* padding: 20; */

    left: 0;
    right: 0;
    margin-left:auto;
    margin-right:auto;
    /* margin: auto; */
    /* padding-top:20px; */

    /* margin-top:30px; */
}


.buttons{
    margin-bottom:0;
    /* height:60px; */
}

