@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

.floating {
    animation: float 2s ease-in-out infinite;
    height: 60px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
}