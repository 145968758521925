/* Centering content horizontally in onboarding sections */
.onBoarding-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 100svh;
    height:100svh;
    text-align: center;
    position: absolute;
    /* bottom:20px; */
    width: 100%;
    transition: transform 0.5s ease-in-out;
    /* max-width: 100vw; */
    /* overflow: hidden */
}

/* .button-line{
    padding-bottom:10
} */
/* Initial positions */
.onBoarding-section {
    transform: translateX(100%);
}

.onBoarding-section.active {
    transform: translateX(0);
}

.onBoarding-section.exiting {
    transform: translateX(-100%);
}

.onBoarding-section.entering {
    transform: translateX(0);
}

.onBoarding-section.returning {
    transform: translateX(100%);
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* Ensure it takes the full width of the parent */
    margin-top: 20px;
    animation: moveUpDown 2s infinite alternate;

}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-40px);
    }
}

.title {
    color: rgb(0, 10, 22);
    font-family: 'Roboto', sans-serif;
}

.content {
    /* color:white; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Center content vertically */
    flex-grow: 1;
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right:0;
    width: 100%;
    padding-left:10px;
    padding-right:10px;
    padding-bottom:10px;
    text-align: center;
    max-height:100px;
     /* padding:0;  */
}


.prev-button {
    margin-right: auto;
}

.next-button {
    margin-left: auto;
}

ion-radio {
    --border-radius: 4px;
    --inner-border-radius: 4px;

    --color: #ddd;
    --color-checked: #383e44;
    margin-bottom: 10px; /* Adjust the value as needed */

}

ion-radio.ios::part(container) {
    width: 20px;
    height: 20px;

    border: 2px solid #ddd;
    border-radius: 4px;
}

.radio-checked.ios::part(container) {
    border-color: #383e44;
}