.my-fab .main-button{
    /* --background-hover: #a3e681; */
    --border-radius: 15px;
}

.my-fab .fab-item {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Add margin to the bottom of the fab-item */
    
}

.my-fab .fab-item .button {
    box-shadow: 0 4px 10px #6b6b6b;

}

.my-fab .fab-item .label {
    position: absolute;
    right: 100%;
    white-space: nowrap;
    height: 24px;
    line-height: 16px;
    padding: 5px 8px;
    border-radius: 12px; /* Increase border-radius to make corners more rounded */
    margin-right: 10px; /* Add margin to the right of the label */
    background: rgba(255, 255, 255, 0.8); /* Add white background with 80% opacity */
    box-shadow: none; /* Remove shadow */
    color: #333; /* Change color to dark grey */
}