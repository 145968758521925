.card-red {
    background-color: rgb(255, 164, 164);
    color:rgb(85, 0, 0)
}

.card-green {
    background-color: rgb(157, 255, 157);
    color:rgb(0, 85, 18)

}

.card-yellow {
    background-color: rgb(253, 253, 205);
    color:rgb(85, 83, 0)

}

.card-grey {
    background-color: grey;
    color:rgb(63, 63, 63)

}